<template>
  <div
    :style="
      liningStyles +
      `margin-left:${
        landing.branding.lining_margin ? '10px' : '0'
      };margin-right:${
        landing.branding.lining_margin ? '10px' : '0'
      };margin-top:${marginTopHeight()};min-height:300px;`
    "
  >
    <div
      style="flex: 1 1 auto"
    >
      <div v-if="$store.state.events.page === 'list'">
        <div
          style="min-height: 0; padding: 15px"
          v-html="htmlData(content.text)"
        />
        <v-timeline
          clipped
          dense
          fill-dot
          align-top
          v-for="(event, eventIndex) in filteredEvents"
          :key="event.id"
          :class="{
            'first--v-timeline': eventIndex === 0,
            'last--v-timeline': filteredEvents.length === eventIndex + 1,
            'disable--v--timeline': filteredEvents.length === 1,
          }"
        >
          <v-timeline-item
            :color="content.styles.date_background"
            large
            dense
            left
          >
            <template v-slot:icon>
              <p
                style="margin-bottom: 0"
                v-if="eventIndex < showDate(event) || showDate(event) === -1"
              >
                <b
                  style="text-align: center"
                  :style="`color:${content.styles.date_color}`"
                  >{{ dateTimeline(event.date) }}</b
                >
              </p>
              <p
                style="text-align: center; margin-bottom: 0"
                :style="`color:${content.styles.date_color}`"
              >
                {{ event.time.slice(0, 5) }}
              </p>
            </template>
            <v-card class="elevation-2" style="margin-right: 15px;">
              <v-card-text style="padding: 0">
                <div
                  style="width: 100%"
                  :style="`height:${content.styles.list_image_height}px;`"
                  v-if="event.main_image"
                >
                  <img
                    :src="event.main_image"
                    alt=""
                    style="width: 100%; height: 100%"
                  />
                </div>
                <div
                  style="padding: 15px"
                  :style="`color:${content.styles.name_color};font-size:${content.styles.name_font_size}px;font-weight:${content.styles.name_font_weight};font-family:${content.styles.name_font_family};font-style:${content.styles.name_font_style};`"
                >
                  {{ event.name[landing.current_lang] }}
                </div>
                <div
                  style="padding: 0 15px"
                  v-html="event.description[landing.current_lang]"
                ></div>
              </v-card-text>
              <v-card-actions style="justify-content: center">
                <v-btn
                  class="preview__open"
                  small
                  style="margin: 0 auto"
                  :style="`color:${content.styles.button_color};background-color:${content.styles.button_background};border:2px solid ${content.styles.button_border};`"
                  depressed
                  @click="selectEvent(event)"
                >
                  More
                </v-btn>
              </v-card-actions>
              <div
                style="
                  min-height: 150px;
                  padding: 15px;
                  border-top: 1px solid #ddd;
                  border-radius: 0;
                "
                v-if="event.show_full"
                v-html="htmlData(event.full_description[landing.current_lang])"
              ></div>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
      <div v-if="$store.state.events.page === 'detail'">
        <TypeEventsDetailPreview
          :content="content"
          :selectedEvent="selectedEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import TypeEventsDetailPreview from "./typeEventDetailPreview";
export default {
  name: "typeEventsPreview",
  components: { TypeEventsDetailPreview },
  data() {
    return {
      dates: [],
      selectedEvent: null,
    };
  },
  computed: {
    ...mapGetters("events", ["sortedEvents"]),
    ...mapState(["landing"]),
    filteredEvents() {
      return this.sortedEvents.filter(
        (event) =>
          +this.$moment().format("x") <=
            +this.$moment(event.date).format("x") &&
          +this.$moment(event.date).format("x") <=
            +this.$moment().add("10", "days").format("x")
      );
    },
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
  },
  props: ["content"],
  methods: {
    marginTopHeight() {
      if (this.selectedEvent&&this.$store.state.events.page === 'detail' && this.selectedEvent.images.length) {
        return "0";
      } else {
        return this.landing.advanced.global.active_header_inside ||
          this.landing.advanced.global.active_logo_inside
          ? "20px"
          : "65px";
      }
    },
    selectEvent(event) {
      this.selectedEvent = event;
      this.$store.state.events.page = 'detail';
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
    dateTimeline(date) {
      return moment(date).format("MMM D");
    },
    showDate(event) {
      const candidate = this.filteredEvents.find(
        (x) => x.date === event.date && x.id !== event.id
      );
      return this.filteredEvents.indexOf(candidate);
    },
    changeEventShow(event) {
      if (event.show_full) {
        event.show_full = 0;
      } else {
        event.show_full = 1;
      }
    },
  },
  async mounted() {
      await this.$store.dispatch(
        "events/getEventListByHotel",
        this.$route.params.id
      );
  },
};
</script>

<style lang="scss">
.v-timeline-item__divider .v-timeline-item__dot--large {
  width: 60px;
  height: 60px;
}
.v-timeline-item__divider
  .v-timeline-item__dot--large
  .v-timeline-item__inner-dot {
  width: 50px;
  height: 50px;
}
.v-timeline-item__divider
  .v-timeline-item__dot--large
  .v-timeline-item__inner-dot {
  flex-direction: column;
  font-size: 11px;
}
.canvas .v-timeline::before {
  top: 20px;
}
.canvas .last--v-timeline::before {
    display: none;
}

.canvas .first--v-timeline::before {
  height: 100%;
}
.canvas .disable--v--timeline::before {
  display: none;
}
.canvas .v-timeline {
  padding-top: 12px;
  & .v-timeline-item {
    padding-bottom: 12px;
  }
}
.v-application p {
  margin-bottom: 0;
}
</style>
