<template>
    <div>
        <div
                class="listing-full"
                :style="
        liningStyles(selectedEvent) +
        `margin-top:${selectedEvent.images.length ? '0' : `${+landing.branding.topMenu.styles.size * 2.5}px`};min-height:300px;`
      "
                style="
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    overflow: hidden;
                  "
        >
            <swiper
                    ref="mySwiper"
                    :direction="'horizontal'"
                    :pagination="{ clickable: true }"
                    :options="swiperOptionsListing"
                    style="overflow: hidden;position: relative"
                    :style="`width: ${
          landing.branding.lining_margin ? '345px' : '360px'
        };height:${content.styles.detail_image_height}px;`"
                    v-if="selectedEvent.images.length"
                    @slideChange="changeSwiperIndex"
            >
                <swiper-slide
                        v-for="img in selectedEvent.images"
                        :key="img"
                        :style="`width: ${
          landing.branding.lining_margin ? '345px' : '360px'
        };`"
                >
                    <img
                            :src="img"
                            alt=""
                            :style="`width: ${
              landing.branding.lining_margin ? '345px' : '360px'
            };height:${content.styles.detail_image_height}px;`"
                    />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" />
            </swiper>
            <div
                    class="listing__play_video listing__play_video_with_img"
                    :style="`top:${
          +content.styles.detail_image_height / 2
        }px;position:absolute;z-index:100;`"
                    v-if="content.video_link && content.images.length && activeIndex === 0"
                    @click="openVideo(selectedEvent.video_link)"
            ></div>
            <div
                    class="listing__play_video listing__play_video_without_img"
                    v-if="content.video_link && content.images.length === 0"
                    @click="openVideo(selectedEvent.video_link)"
            ></div>
            <div
                    style="height: auto; min-height: 100px; width: 100%; padding: 10px"
                    v-html="htmlData(selectedEvent.full_description[landing.current_lang])"
            ></div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import moment from "moment";
    import "swiper/swiper-bundle.css";
    import "swiper/modules/pagination/pagination.min.css";
    import SwiperCore, { Pagination } from "swiper";
    import { Swiper, SwiperSlide } from "vue-awesome-swiper";
    SwiperCore.use([Pagination]);
    export default {
        name: "typeEventsDetailPreview",
        destroyed() {
            this.$store.state.events.page = 'list';
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        computed: {
            ...mapState(["landing"]),
            mySwiper: () => {
                return this.$refs.mySwiper.swiper;
            },
        },
        props: ["content","selectedEvent"],
        data() {
            return {
                activeIndex: 0,
                swiperOptionsListing: {
                    grabCursor: true,
                    centeredSlides: true,
                    pagination: {
                        el: ".swiper-pagination",
                        type: "bullets",
                        clickable: true,
                    },
                },
            };
        },
        methods: {
            liningStyles(listing_item) {
                let styles;
                if (this.landing.branding.bg_lining_img) {
                    styles = `background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
                } else {
                    styles = `background-color:${this.landing.branding.bg_lining_color};`;
                }
                if (listing_item) {
                    if (listing_item.images.length) {
                        styles += `border-bottom-left-radius:${this.landing.branding.lining_radius}px;border-bottom-right-radius:${this.landing.branding.lining_radius}px;`;
                    } else {
                        styles += `border-radius:${this.landing.branding.lining_radius}px;`;
                    }
                }
                return styles;
            },
            changeSwiperIndex() {
                this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
            },
            openVideo(link) {
                window.open(link, "_blank");
            },
            timeNow() {
                this.datenow = moment().format("HH:mm");
                setInterval(() => {
                    this.datenow = moment().format("HH:mm");
                }, 1000);
            },
            htmlData(data, type) {
                if (data) {
                    data = data.replaceAll(':name:', `${this.selectedEvent.name[this.landing.current_lang]}`);
                    data = data.replaceAll(':date:', `${this.$moment(this.selectedEvent.date).format('DD.MM.YYYY')} ${this.selectedEvent.time}`);
                    const weatherInd = data.split(" ").indexOf(":weather:");
                    let result = data.split(" ");
                    if (weatherInd && this.landing.advanced.global.show_weather) {
                        const newArr = result;
                        newArr[weatherInd] =
                            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
                                0
                            ) + " ℃";
                        result = newArr;
                    }
                    const timeInd = data.split(" ").indexOf(":time:");
                    if (timeInd) {
                        const newArr = result;
                        newArr[timeInd] = this.datenow;
                        result = newArr;
                    }
                    const dateInd = data.split(" ").indexOf(":date:");
                    if (dateInd) {
                        const newArr = result;
                        newArr[dateInd] = this.dateToday;
                        result = newArr;
                    }
                    const user_name = data.split(" ").indexOf(":name:");
                    if (
                        user_name &&
                        this.landing.advanced.global.auth &&
                        this.landing.advanced.auth.name &&
                        type === "title"
                    ) {
                        const newArr = result;
                        newArr[user_name] = "Agent";
                        result = newArr;
                    }
                    const user_surname = data.split(" ").indexOf(":surname:");
                    if (
                        user_surname &&
                        this.landing.advanced.global.auth &&
                        this.landing.advanced.auth.name &&
                        type === "title"
                    ) {
                        const newArr = result;
                        newArr[user_surname] = "Smith";
                        result = newArr;
                    }
                    const user_birthday = data.split(" ").indexOf(":birthday:");
                    if (
                        user_surname &&
                        this.landing.advanced.global.auth &&
                        this.landing.advanced.auth.name &&
                        type === "title"
                    ) {
                        const newArr = result;
                        newArr[user_birthday] = "24.03.1999";
                        result = newArr;
                    }
                    const user_room = data.split(" ").indexOf(":room:");
                    if (
                        user_surname &&
                        this.landing.advanced.global.auth &&
                        this.landing.advanced.auth.name &&
                        type === "title"
                    ) {
                        const newArr = result;
                        newArr[user_room] = "707";
                        result = newArr;
                    }

                    const user_auth = data.split(" ").indexOf(":auth:");
                    if (user_auth && this.landing.advanced.global.auth && !type) {
                        const newArr = result;
                        newArr[user_auth] = `<a href="#">Login</a>`;
                        result = newArr;
                    }
                    const user_logout = data.split(" ").indexOf(":logout:");
                    if (user_logout && this.landing.advanced.global.auth && !type) {
                        const newArr = result;
                        newArr[user_logout] = `<a href="#">Logout</a>`;
                        result = newArr;
                    }
                    return result.join(" ");
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
